<template>
  <div class="px-4 lg:px-0 pt-[2.8125rem] pb-10 lg:py-20">
    <div class="faq mx-auto" :class="{'max-w-wrapper-sm':!compact}">
      <template v-if="title">
        <component
          :is="titleTag"
          v-if="title"
          class="faq__title mb-5 text-2xl leading-8 font-bold"
        >
          {{ title }}
        </component>

        <hr
          class="
          relative border-0 border-b border-dark/20 mb-4 sm:mb-10 overflow-visible
          after:content-[''] after:max-w-[6.25rem] after:sm:max-w-[13.5rem] after:w-1/4 after:h-2 after:bg-primary after:absolute after:-top-[0.1875rem]
        "
        />
      </template>

      <div
        v-if="$slots.default"
        :class="{'mb-6 sm:mb-10':!compact}"
        class="faq__desc text-base leading-6"
      >
        <slot />
      </div>

      <div
        v-if="list && list.length > 0"
        class="faq__list"
      >
        <!-- :ref="`faq-${itemIndex}`" -->
        <atoms-accordion
          v-for="(item, itemIndex) in list"
          :key="itemIndex"
          ref="faqItems"
          :border="border"
          :icon-type="iconType"
          :show-title-icon="showTitleIcon"
          :size="size"
          :class="accordionClass"
          @click="handleStatus(itemIndex)"
        >
          <!-- eslint-disable vue/no-v-html -->
          <template
            v-if="showTitleIcon"
            #title-icon
          >
            <IconsCheck
              v-if="item.check === 'Yes'"
              fill="#0AC16A"
              class="align-baseline inline"
            />

            <IconsClose
              v-else
              type="default"
              width="20"
              height="18"
              fill="#C50E0E"
              class="align-baseline inline -ml-[0.3125rem] -mr-[0.1875rem]"
            />
          </template>

          <template #title>
            <div v-html="item.title"></div>
          </template>

          <template #content>
            <div :class="{'text-sm':compact}" v-html="item.content"></div>
          </template>
        </atoms-accordion>
      </div>

      <div
        v-if="disclaimer"
        class="faq__desc my-6 sm:my-10 text-base leading-6"
      >
        {{ disclaimer }}
      </div>
    </div>
  </div>
</template>

<script setup>
defineOptions({
  name: 'MoleculesFaq'
})

const props = defineProps({
  title: {
    type: String,
    default: '',
    required: true
  },

  disclaimer: {
    type: String,
    default: ''
  },

  list: {
    type: Array,
    default: () => {
      return []
    }
  },

  multipleCollapse: {
    type: Boolean,
    default: false
  },

  size: {
    type: String,
    default: 'default',
    validator: value => ['default', 'small'].includes(value)
  },

  titleTag: {
    type: String,
    default: 'p',
    validator: value => {
      if (value.indexOf('h') === 0 && value.length === 2) {
        return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value)
      }

      return value
    }
  },

  iconType: {
    type: String,
    default: 'default',
    validator: value => ['default', 'arrow'].includes(value)
  },

  showTitleIcon: {
    type: Boolean,
    default: false
  },

  accordionClass: {
    type: String,
    default: ''
  },

  border: {
    type: Boolean,
    default: true
  },

  tabWrapperClass: {
    type: String,
    default: ''
  },

  hideSchema: {
    type: Boolean,
    default: false
  },
  compact: {
    type: Boolean,
    default: false
  }
})

const faqItems = ref({})

const handleStatus = key => {
  if (props.multipleCollapse) {
    return
  }

  const refs = faqItems.value

  for (let i = 0; i < refs.length; i++) {
    const curr = refs[i]

    if (i !== key) {
      curr.status = false
    }
  }
}

useJsonld(() => {
  if (props.list.length < 1 || props.hideSchema) {
    return {}
  }

  const items = props.list.map(item => ({
    '@type': 'Question',
    name: item.title,
    acceptedAnswer: {
      '@type': 'Answer',
      text: item.content
    }
  }))

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: items
  }
})
</script>
